import {IPendientes} from 'src/models/Pendientes'

interface IPendienteProp {
  pendientes: IPendientes
}

const PendienteWithdrawal = ({pendientes}: IPendienteProp) => {
  return (
    <>
      <div className='mt-15 mb-10 text-center'>
        <h4 className='retirar-sub-title'>Transacciones en Verificación</h4>
      </div>
      <div className='table-responsive pb-15'>
        <table className='table text-center retirar-table-head'>
          <thead className='table-light retirar-table-head retirar-table-title'>
            <tr>
              <th className='fw-bold'>&nbsp;# Transacción</th>
              <th className='fw-bold'>Fecha de transacción</th>
              <th className='fw-bold'>Retiro&nbsp;</th>
            </tr>
          </thead>
          <tbody className='border'>
            {pendientes.data.map((value, i) => {
              return (
                <tr className='border' key={i}>
                  <th>{value.id}</th>
                  <td className='fw-bolder text-black'>{value.created_at.substring(0, 10)}</td>
                  <td className='fw-bolder text-black'>{value.cantidad_depositada}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='mt-5'>
          <li>
            Los retiros efectuados hasta las <span className='fw-bolder'>11:00 GMT-5</span> del día
            Martes se pagarán el Miércoles entre las{' '}
            <span className='fw-bolder'>14:00 y 22:00</span> horas.
          </li>
          <li>
            Los retiros efectuados hasta las <span className='fw-bolder'>11:00 GMT-5</span> del día
            Jueves se pagarán el Viernes entre las <span className='fw-bolder'>14:00 y 22:00</span>{' '}
            horas.
          </li>
          <li>
            Cada retiro realizado tiene un costo de <span className='fw-bolder'>2USD</span> por
            comisión de red TRC20, este costo será descontado del retiro.
          </li>
        </div>
      </div>
    </>
  )
}

export {PendienteWithdrawal}
