import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {toAbsoluteUrl} from 'src/_metronic/helpers'

const HeaderNotificationsMenu: React.FC = () => {
  // Detectar si es móvil para cambiar la imagen
  const isMobile = window.innerWidth <= 768

  // Estados para mostrar/ocultar los modales
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false)

  return (
    <>
      {/* Menú principal de herramientas */}
      <div className='menu menu-sub menu-sub-dropdown tools tools-title-radius' data-kt-menu='true'>
        <div>
          {/* Encabezado */}
          <div className='d-flex flex-column tools-header' style={{background: '#0072C1'}}>
            <div className='d-flex align-items-center my-10 px-10'>
              <div className='symbol symbol-circle symbol-50px me-5'>
                <img alt='tools' src={toAbsoluteUrl('/media/icons/duotune/general/tools.png')} />
              </div>
              <h3 className='tools-title m-0 p-0'>Herramientas</h3>
            </div>

            {/* Tabs */}
            <ul className='nav nav-line-tabs px-10 tools-sub-title d-flex justify-content-between'>
              <li className='nav-item'>
                <a
                  className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
                  data-bs-toggle='tab'
                  href='#kt_topbar_notifications_1'
                  translate='no'
                >
                  Planes
                </a>
              </li>
              <li className='nav-item'>
                {/* Quita data-bs-* si no usas Bootstrap nativo, o simplemente ignóralo */}
                <a
                  className='nav-link text-white opacity-75 opacity-state-100 pb-4'
                  data-bs-toggle='tab'
                  href='#kt_topbar_notifications_2'
                >
                  Depositos
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-white opacity-75 opacity-state-100 pb-4'
                  data-bs-toggle='tab'
                  href='#kt_topbar_notifications_3'
                >
                  Retiros
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-white opacity-75 opacity-state-100 pb-4'
                  data-bs-toggle='tab'
                  href='#kt_topbar_notifications_4'
                >
                  Transferencias
                </a>
              </li>
            </ul>
          </div>

          <div className='tab-content'>
            {/* Tab: Planes */}
            <div
              className='tab-pane fade show active'
              id='kt_topbar_notifications_1'
              role='tabpanel'
            >
              <div className='d-flex flex-column px-9'>
                <div className='pt-10 pb-0'>
                  <h3 className='tools-content-title'>Activa tu cuenta</h3>
                  <div className='tools-content-text'>
                    En BlueTradingCorp contamos con planes acordes a tu capacidad de negociación.
                  </div>
                  <div className='text-center mt-5 mb-9'>
                    <button
                      type='button'
                      className='fs-1 btn btn-primary fw-bolder rounded-pill py-2'
                      // Aquí NO usamos data-bs-toggle, sino tu propia lógica
                      onClick={() => {
                        // En caso de querer mostrar un modal si existiera uno:
                        // setShowSomething(true)
                      }}
                      translate='no'
                    >
                      Adquirir plan
                    </button>
                  </div>
                </div>

                <div className='text-center'>
                  <img
                    className='w-200px'
                    alt='metronic'
                    src={toAbsoluteUrl('/media/illustrations/sketchy-1/tools.png')}
                  />
                </div>
              </div>
            </div>

            {/* Tab: Depósitos */}
            <div className='tab-pane fade show' id='kt_topbar_notifications_2' role='tabpanel'>
              <div className='d-flex flex-column px-9'>
                <div className='pt-10 pb-0'>
                  <h3 className='tools-content-title'>Deposita a tu cuenta</h3>
                  <div className='tools-content-text'>
                    En BlueTrading Corp contamos con opciones de negocios estratégicas, capaces de
                    hacerte alcanzar tus metas y hacerlas realidad.
                  </div>
                  <div className='text-center mt-5 mb-9'>
                    <button
                      type='button'
                      className='fs-1 btn btn-primary fw-bolder rounded-pill py-2'
                      // En lugar de data-bs-target, usamos nuestro setShowDepositModal
                      onClick={() => setShowDepositModal(true)}
                    >
                      Depositar
                    </button>
                  </div>
                </div>

                <div className='text-center'>
                  <img
                    className='w-200px'
                    alt='metronic'
                    src={toAbsoluteUrl('/media/illustrations/sketchy-1/tools.png')}
                  />
                </div>
              </div>
            </div>

            {/* Tab: Retiros */}
            <div className='tab-pane fade show' id='kt_topbar_notifications_3' role='tabpanel'>
              <div className='d-flex flex-column px-9'>
                <div className='pt-10 pb-0'>
                  <h3 className='tools-content-title'>Retira de tu cuenta</h3>
                  <div className='tools-content-text'>
                    En <span translate='no'>Blue Trading</span> puedes retirar tu saldo disponible
                    en cualquier momento.
                  </div>
                  <div className='text-center mt-5 mb-9'>
                    <button
                      type='button'
                      className='fs-1 btn btn-primary fw-bolder rounded-pill py-2'
                      onClick={() => setShowWithdrawalModal(true)}
                    >
                      Retirar
                    </button>
                  </div>
                </div>

                <div className='text-center'>
                  <img
                    className='w-200px'
                    alt='metronic'
                    src={toAbsoluteUrl('/media/illustrations/sketchy-1/tools.png')}
                  />
                </div>
              </div>
            </div>

            {/* Tab: Transferencias */}
            <div className='tab-pane fade show' id='kt_topbar_notifications_4' role='tabpanel'>
              <div className='d-flex flex-column px-9'>
                <div className='pt-10 pb-0'>
                  <h3 className='tools-content-title'>Transfiere a usuarios blue</h3>
                  <div className='tools-content-text'>
                    En blue tenemos opciones ilimitadas para que transfieras a otros usuarios blue
                    por solo $2 USD.
                  </div>
                  <div className='text-center mt-5 mb-9'>
                    <button
                      type='button'
                      className='fs-1 btn btn-primary fw-bolder rounded-pill py-2'
                      onClick={() => {
                        // Si tuvieras un modal de Transfer
                      }}
                    >
                      Transferir
                    </button>
                  </div>
                </div>

                <div className='text-center'>
                  <img
                    className='w-200px'
                    alt='metronic'
                    src={toAbsoluteUrl('/media/illustrations/sketchy-1/tools.png')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------------------------------- */}
      {/*                 Modal Temporal Depósitos / Retiros (React-Bootstrap)    */}
      {/* ---------------------------------------------------------------- */}

      {/* Modal de Depósitos */}
      <Modal
        show={showDepositModal}
        onHide={() => setShowDepositModal(false)}
        centered
        backdrop='static'
        keyboard={true}
        // Puedes agregar className extra si quieres estilizar
      >
        <Modal.Body className='p-0 position-relative'>
          {/* Mostrar imagen según sea móvil o desktop */}
          {isMobile ? (
            <img
              className='img-fluid w-100'
              src={toAbsoluteUrl('/media/illustrations/Responsive.webp')}
              alt='Responsive'
              style={{pointerEvents: 'none'}}
            />
          ) : (
            <img
              className='img-fluid w-100'
              src={toAbsoluteUrl('/media/illustrations/Desktop.webp')}
              alt='Desktop'
              style={{pointerEvents: 'none'}}
            />
          )}

          {/* Botón de cierre (arriba a la derecha) */}
          <button
            type='button'
            className='btn-close'
            onClick={() => setShowDepositModal(false)}
            style={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
            }}
          />

          {/* Botón (imagen) centrado en la parte inferior */}
          <a
            href='https://api.whatsapp.com/send?phone=16162741115&text=Hola%20equipo%20Blue%20Requiero%20informacion%20adicional%20sobre%20los%20retiros%20y%20depositos'
            target='_blank'
            rel='noreferrer'
            style={{
              position: 'absolute',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <img
              src={toAbsoluteUrl('/media/illustrations/Boton.webp')}
              alt='Botón'
              style={{cursor: 'pointer'}}
            />
          </a>
        </Modal.Body>
      </Modal>

      {/* Modal de Retiros */}
      <Modal
        show={showWithdrawalModal}
        onHide={() => setShowWithdrawalModal(false)}
        centered
        backdrop='static'
        keyboard={true}
      >
        <Modal.Body className='p-0 position-relative'>
          {isMobile ? (
            <img
              className='img-fluid w-100'
              src={toAbsoluteUrl('/media/illustrations/Responsive.webp')}
              alt='Responsive'
              style={{pointerEvents: 'none'}}
            />
          ) : (
            <img
              className='img-fluid w-100'
              src={toAbsoluteUrl('/media/illustrations/Desktop.webp')}
              alt='Desktop'
              style={{pointerEvents: 'none'}}
            />
          )}

          {/* Botón de cierre */}
          <button
            type='button'
            className='btn-close'
            onClick={() => setShowWithdrawalModal(false)}
            style={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
            }}
          />

          {/* Botón (imagen) centrado en la parte inferior */}
          <a
            href='https://api.whatsapp.com/send?phone=16162741115&text=Hola%20equipo%20Blue%20Requiero%20informacion%20adicional%20sobre%20los%20retiros%20y%20depositos'
            target='_blank'
            rel='noreferrer'
            style={{
              position: 'absolute',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <img
              src={toAbsoluteUrl('/media/illustrations/Boton.webp')}
              alt='Botón'
              style={{cursor: 'pointer'}}
            />
          </a>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default HeaderNotificationsMenu
